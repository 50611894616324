var site = site || {};
site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function() { return ''; };

$(document).ready(function(e) {

      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;
      if (signedIn == '0') signedIn = false;
      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');

      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.first_name;
      var firstTime = persistentCookie.first_time;
      var userDetected = !!firstName;
      var isMobile = !$('body').hasClass('device-pc');
      var $greetingContainer = $('.user-greeting__name');

      // User greeting and login/logout link below Account button
      var userLoginState = $('.user-login-state');
      if (userDetected && !isMobile) {
        if (signedIn) {
          if ($greetingContainer.length) {
            firstName = firstName.replace(/\+/g, ' ');
            $greetingContainer.html(' ' + firstName);
          }
        } else {
          $('.user-logged-out').empty();
          $('.user-logged-in').empty();
        }
      }

});
